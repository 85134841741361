import * as React from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import { IDealImageFormItemProps } from './DealImageFormItem.types';

import styles from './DealImageFormItem.module.scss';

const { Dragger } = Upload;

export const DealImageFormItem: React.FC<IDealImageFormItemProps> = (props) => {
  function onChange(values: { fileList: any[] }): void {
    props.onChange && props.onChange(values.fileList);
  }

  return (
    <Dragger
      accept="image/*"
      multiple
      // do not let this component auto upload on change
      beforeUpload={() => false}
      onChange={onChange}
    >
      <CloudUploadOutlined className={styles.DealImageFormItem__uploadIcon} />
      <p>Click or drag file to this area to upload</p>
    </Dragger>
  );
}