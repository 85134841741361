import { useSelector } from 'react-redux';
import { getIsLoggedIn } from 'src/redux/auth/auth.selector';

export function useModalWithAuthCheck(
  showModal: (...params: any) => void,
  loggedInFunc: (...params: any) => void,
) {
  const isLoggedIn = useSelector(getIsLoggedIn);

  function showModalIfUnathenticated(...params: any) {
    if (isLoggedIn) return loggedInFunc(...params);

    showModal(...params);
  }

  return {
    showModal: showModalIfUnathenticated,
  };
}
