import * as React from 'react';
import { Button, Input } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import { IPriceInputProps } from './PriceInput.types';

import styles from './PriceInput.module.scss';

// consider using a checkbox plus input filter
// checkboxes with result counts are useful
export const PriceInput: React.FC<IPriceInputProps> = (props) => {
  const [maxPrice, setMaxPrice] = React.useState<string|undefined>(undefined);
  const [minPrice, setMinPrice] = React.useState<string|undefined>(undefined);

  function onMaxPriceChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMaxPrice(e.target.value);
  }

  function onMinPriceChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMinPrice(e.target.value);
  }

  function onSubmit() {
    // e, +, - characters can result in ''
    props.onChange([
      minPrice || undefined,
      maxPrice || undefined,
    ]);
  }

  return (
    <div>
      <Input
        className={styles.PriceInput__input}
        defaultValue={props.minPrice}
        inputMode="numeric"
        onChange={onMinPriceChange}
        prefix="$"
        type="number"
      />
      <span className={styles.PriceInput__text}>to</span>
      <Input
        className={styles.PriceInput__input}
        defaultValue={props.maxPrice}
        inputMode="numeric"
        onChange={onMaxPriceChange}
        prefix="$"
        type="number"
      />
      <Button className={styles.PriceInput__btn} onClick={onSubmit}>
        <CaretRightOutlined />
      </Button>
    </div>
  );
}