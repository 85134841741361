import * as React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Layout } from 'antd';
import { isDev } from 'src/utils/env';

import styles from 'src/layouts/LoggedOutLayout/LoggedOutLayout.module.scss';

import { Footer } from 'src/components/Footer';
import { LoggedOutHeader } from 'src/components/Header';

import { CreateUserPage } from 'src/pages/admin/CreateUserPage';
import { DealPage } from 'src/pages/DealPage';
import { LandingPage } from 'src/pages/LandingPage';
import { LoginPage } from 'src/pages/LoginPage';
import { NftPage } from 'src/pages/NftPage';
import { NotFoundPage } from 'src/pages/NotFoundPage';
import { PostDealPage } from 'src/pages/PostDealPage';
import { SearchPage } from 'src/pages/SearchPage';
import { SignupPage } from 'src/pages/SignupPage';
import { TermsOfService } from 'src/pages/TermsOfServicePage';
import { UpdatePasswordPage } from 'src/pages/UpdatePasswordPage';

const { Content } = Layout;

export interface LoggedOutLayoutProps {
}

export const LoggedOutLayout: React.FunctionComponent<LoggedOutLayoutProps> =
function (props): React.ReactElement {
  return (
    <Layout className={styles.layout}>
      <LoggedOutHeader />
      <Content className={styles.content}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {isDev && <Route path="/admin/create-user" element={<CreateUserPage />} />}
          <Route path="/deal/:dealId/:dealTitle" element={<DealPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/nft" element={<NftPage />} />
          <Route path="/post-deal" element={<PostDealPage />} /> {/* TODO redirect to signup */}
          <Route path="/search" element={<SearchPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/update-password" element={<UpdatePasswordPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Content>
      <Footer />
    </Layout>
  );
}

export default React.memo(LoggedOutLayout);
