// keep in sync with backend
// src/search/search.constants.ts
export enum ESortOptionText {
  'bestMatch' = 'Best match',
  'rating' = 'Rating',
  'newestPost' = 'Newest',
  'oldestPost' = 'Oldest',
  'lowestPrice' = 'Lowest price first',
  'highestPrice' = 'Highest price first',
}

export interface ISortSelectProps {
  onChange: (val: keyof typeof ESortOptionText) => void;
}