import * as React from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { SEARCH_QP_KEY } from 'src/constants/search';

import { ISearchBarFormProps } from './SearchBarForm.types';

export const SearchBarForm: React.FC<ISearchBarFormProps> = (props) => {
  const [search, setSearch] = React.useState<string|undefined>(undefined);
  
  const [searchParams] = useSearchParams();
  const q: string | null = searchParams.get(SEARCH_QP_KEY);
  const navigate = useNavigate();

  React.useEffect(() => {
    setSearch(q || undefined);
  }, [q]);
  
  function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setSearch(e.target.value);
  }

  function onSubmit() {
    // don't search if empty
    if (!search) return;

    const params = {[SEARCH_QP_KEY]: search || ''};
    
    navigate({
      pathname: '/search',
      search: `?${createSearchParams(params)}`,
    });
  }

  function onKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }

  return (
    <Form className={props.className}>
      <Input
        defaultValue={q || ''}
        placeholder="Search deals"
        onChange={onChange}
        onKeyUp={onKeyUp}
        suffix={
          <Button onClick={onSubmit} type="primary">
            Search
          </Button>
        }
        value={search}
      />
    </Form>
  );
}