import * as React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import { Helmet } from 'react-helmet';
import { META_DESCRIPTION, META_TITLE, PROD_URL } from './app.constants';

import LoggedInLayout from 'src/layouts/LoggedInLayout';
import LoggedOutLayout from 'src/layouts/LoggedOutLayout';
import { getIsLoggedIn } from 'src/redux/auth/auth.selector';

const { PUBLIC_URL } = process.env;

// Cannot render as component as react-helmet doesn't work with
// document fragments
function defaultMetaTags() {
  return [
    <meta charSet="utf-8" />,
    <link rel="icon" href={`${PUBLIC_URL}/favicon.ico`} />,
    <meta name="viewport" content="width=device-width, initial-scale=1" />,
    <meta name="theme-color" content="#000000" />,
    <meta
      name="description"
      content={META_DESCRIPTION}
    />,
    <link rel="apple-touch-icon" href={`${PUBLIC_URL}/logo_256.png`} />,
    <link rel="manifest" href={`${PUBLIC_URL}/manifest.json`} />,
    <title>{META_TITLE}</title>,
  ];
}

function defaultFbMetaTags() {
  return [
    <meta property="og:type" content="website" />,
    <meta property="og:url" content={PROD_URL} />,
    <meta property="og:title" content={META_TITLE} />,
    <meta property="og:image" content={`${PUBLIC_URL}/og_logo.png`} />,
  ];
}

function defaultTwitterMetaTags() {
  return [
    <meta property="twitter:card" content="summary_large_image" />,
    <meta property="twitter:url" content={PROD_URL} />,
    <meta property="twitter:title" content={META_TITLE} />,
    <meta property="twitter:description" content={META_DESCRIPTION} />,
    <meta property="twitter:image" content={`${PUBLIC_URL}/og_logo.png`} />,
  ];
}

export default function App() {
  React.useEffect(() => {
    function unload() {
      window.removeEventListener('beforeunload', unload);
    }

    window.addEventListener('beforeunload', unload);
    // remove existing fb meta tags (they override react-helmet's)
    document.querySelectorAll('meta[property^="og"]').forEach((el) => el.remove());
    return unload;
  }, []);

  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <>
      <Helmet>
        {defaultMetaTags()}
        {defaultFbMetaTags()}
        {defaultTwitterMetaTags()}
      </Helmet>
      <Routes>
        <Route path="*" element={isLoggedIn ? <LoggedInLayout /> : <LoggedOutLayout />} />
      </Routes>
    </>
  );
};
