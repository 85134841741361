import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';

import type { ISearchFilterFormProps } from './SearchFilterForm.types';
import { DaysBeforeSelect } from './DaysBeforeSelect';
import { PriceInput } from './PriceInput';
import { SortSelect } from './SortSelect';

import styles from './SearchFilterForm.module.scss';

// filters
//
// sorting - select [sort=rating]
//   relevance, rating, newest, oldest, lowest price first, highest price first,
//   *last activity [comments, post updates]
// *rating
// date - select [daysBefore=14]
// reset - button
// price - select & input [minPrice=50&maxPrice=100] TODO: currency conversion
// *category
// *retailers (stores)
// **brands
// **deal type
// **location
// **feature/spec (e.g. 1080p)
// ***users (filter by username - probably not needed)
// **hide expired deals

export const SearchFilterForm: React.FC<ISearchFilterFormProps> = (props) => {
  return (
    <Form layout="vertical">
      <div className={styles.SearchFilterForm__resetLinkWrapper}>
        <Link to={props.resetLinkTo}>Reset</Link>
      </div>
      <Form.Item
        label="Sort"
      >
        <SortSelect onChange={props.onSortChange} />
      </Form.Item>
      <Form.Item
        label="Date"
      >
        <DaysBeforeSelect onChange={props.onDaysBeforeChange} />
      </Form.Item>
      <Form.Item
        label="Price"
      >
        <PriceInput
          maxPrice={props.maxPrice}
          minPrice={props.minPrice}
          onChange={props.onPriceChange}
        />
      </Form.Item>
    </Form>
  );
}