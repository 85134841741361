import * as React from 'react';
import { Card } from 'antd';
import { FireTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { format } from 'timeago.js';
import { formatMoney } from 'src/utils/currency';
import { isDealHot } from 'src/utils/deals';
import { useModal } from 'src/components/GlobalModal/LoginOrSignupModal';
import { useModalWithAuthCheck } from 'src/components/GlobalModal';
import { encodeDealTitle } from './utils';

import { IDealCardProps } from './DealCard.types';
import styles from './DealCard.module.scss';
import { UpVoteButton } from 'src/components/UpVoteButton';

export const DealCard: React.FC<IDealCardProps> = (props) => {
  function upVote() {
  }
  
  const { showModal } =
    useModalWithAuthCheck(
      useModal().showModal,
      upVote,
    );

  function onUpVote(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    showModal();
  }

  
  return (
    <Link className={styles.DealCard} to={`/deal/${props.id}/${encodeDealTitle(props.title)}`}>
      <Card
        bordered={false}
        cover={
          <div className={styles.DealCard__coverContent}>
            <img className={styles.DealCard__coverImage} src={props.imageUrls[0]} alt="deal_cover_image" />
          </div>
        }
        hoverable
      >
        <div className={styles.DealCard__title}>
          {props.title}
        </div>
        <div className={styles.DealCard__details}>
          <div>
            <div className={styles.DealCard__dealPrice}>
              {formatMoney(props.dealPrice, props.currency)}
            </div>
            {!!props.retailPrice && <div className={styles.DealCard__retailPrice}>
              {formatMoney(props.retailPrice, props.currency)}
            </div>}
          </div>
          <div className={styles.DealCard__rating}>
            <UpVoteButton
              className={styles.DealCard__ratingIconWrapper}
              onClick={onUpVote}
            />
            <span>{!!props.rating && props.rating}</span>
          </div>
        </div>
        <div className={styles.DealCard__createdAt}>
          posted {format(props.createdAt)}
        </div>
        <div className={styles.DealCard__badge}>
          {isDealHot(props) && <FireTwoTone twoToneColor="#d32323" />}
        </div>
      </Card>
    </Link>
  );
}
