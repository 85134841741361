import * as React from 'react';
import { Link } from 'react-router-dom';
import { Affix, Button, Col, Row } from 'antd';
import { useModal } from '../GlobalModal/LoginOrSignupModal';

import { SearchBarForm } from './SearchBarForm';

import { IHeaderProps } from './Header.types';

import styles from './Header.module.scss';
import { Logo } from '../Logo';

export const LoggedOutHeader: React.FC<IHeaderProps> = function(props) {
  const { showModal } = useModal();

  return (
    <div className={styles.Header}>
      <Row className={styles.Header__menuBar}>
        <Col md={6} className={styles.Header__leftSection}>
          <Link className={styles.Header__logo} to="/"><Logo size={28} /> Melon</Link>
        </Col>
        <Col xs={24} md={12}>
          <div className={styles.Header__menuBarContent}>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to="/login">Login</Link>
              <Link to="/signup">Signup</Link>
            </div>
          </div>
        </Col>
      </Row>
      <Affix className={styles.Header__affix} offsetTop={16}>
        <Row className={styles.Header__actionBarRow}>
          <Col md={6} className={styles.Header__leftSection}>
            <Link className={styles.Header__logo} to="/"><Logo size={28} /> Melon</Link>
          </Col>
          <Col xs={24} md={12}>
            <div className={styles.Header__actionBar}>
              <SearchBarForm className={styles.Header__searchForm} />
              <Button onClick={showModal} type="primary">Share a deal</Button>
            </div>
          </Col>
        </Row>
      </Affix>
    </div>
  );
}
