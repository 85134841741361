import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'antd';
import { SORT_QP_KEY } from 'src/constants/search';

import { ISortSelectProps, ESortOptionText } from './SortSelect.types';

const { Option } = Select;

// Think about adding last activity [comments, post updates]
export const SortSelect: React.FC<ISortSelectProps> = (props) => {
  const [searchParams] = useSearchParams();
  const sort = searchParams.get(SORT_QP_KEY);

  return (
    <Select
      defaultValue="bestMatch"
      onChange={props.onChange}
      value={sort as any || 'bestMatch'}
    >
      {Object.entries(ESortOptionText)
        .map(([key, val]: [string, string], i: number) => (
          <Option key={i} value={key}>{val}</Option>
      ))}
    </Select>
  );
}