import * as React from 'react';

import { IImportImageProps } from './ImportImage.types';

export const ImportImage: React.FC<IImportImageProps> = (props) => {
  const pixelSize = typeof props.size === 'number' ? `${props.size}px` : undefined;
  const style = {
    height: pixelSize,
    width: pixelSize, 
  };

  return (
    <img src={props.src} style={style} alt="logo" />
  );
}