import * as React from 'react';
import { useMutation } from 'react-query';
import { Form, Input, Button } from 'antd';

import { ISignupFormProps } from './SignupForm.types';

import styles from './SignupForm.module.scss';

export const SignupForm: React.FC<ISignupFormProps> = (props): React.ReactElement => {
  const {
    isLoading,
    mutate, 
  } = useMutation(props.action, {
    onError: (err: any) => {
      // TODO handle error
      // props.onFail && props.onFail(err);
    },
    onSuccess: props.onSuccess,
  });

  function onFinish(values: {
    email: string;
    password: string;
    username: string;
  }) {
    mutate(values);
  }
  
  return (
    <Form
      labelCol={{
        span: 8,
      }}
      layout={props.layout}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter your email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item className={styles.SignupForm__submitBtnWrapper}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Signup 
        </Button>
      </Form.Item>
    </Form>
  );
};

export default React.memo(SignupForm);
