import * as React from 'react';
import { Affix, Col, Row } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import equal from 'fast-deep-equal/es6';
import { DealGrid } from 'src/components/DealGrid';
import { SearchFilterPanel } from 'src/components/SearchFilterPanel';
import { useDealSearchParams } from 'src/constants/search';

import { getSearch } from 'src/api/search';

import styles from './SearchPage.module.scss';
import { NoResults } from './NoResults';

const SEARCH_QUERY_NAME = 'getSearchDealList';

export const SearchPage: React.FC = () => {
  const queryClient = useQueryClient();
  const queryParams = useDealSearchParams();
  const { isLoading, error, data: deals, refetch } = useQuery(SEARCH_QUERY_NAME, async function({ signal }) {
    return getSearch(queryParams, {
      signal,
    });
  });
  // queryParams is a new object reference every render
  // use this to avoid infinite fetch and re-render
  const [lastQueryParams, setLastQueryParams] = React.useState({});

  React.useEffect(() => {
    if (!equal(queryParams, lastQueryParams)) {
      queryClient.cancelQueries(SEARCH_QUERY_NAME);
      setLastQueryParams(queryParams);
      refetch();
    }
    // TODO cancel getSearch on unmount
  // only need to check lastQueryParams, setLastQueryParams, refetch on queryParams change
  }, [queryParams]);

  // TODO make this better
  if (isLoading) return null;
  if (error) return null;

  // TODO update with axios
  if (!deals) return null;
  
  return (
    <div className={styles.SearchPage}>
      <Row gutter={24}>
        <Col
          className={styles.SearchPage__gridCol}
          xs={24}
          md={{ offset: 1, span: 6 }}
        >
          <Affix offsetTop={96} style={{ maxHeight: '100%' }}>
            <SearchFilterPanel />
          </Affix>
        </Col>
        <Col className={styles.SearchPage__gridCol} xs={24} sm={24} md={16}>
          {deals.length ?
            <DealGrid
              cardsPerRow={{
                xs: 1,
                sm: 1,
                md: 3,
                lg: 3,
                xl: 4,
              }}
              deals={deals}
            /> :
            <NoResults searchParams={queryParams} />
          }
        </Col>
      </Row>
    </div>
  );
}