import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { LoginForm } from 'src/components/forms/LoginForm';
import { GlobalModalContext } from '../GlobalModal.context';
import { useModalWithAuthCheck } from '../GlobalModal.hooks';

import styles from './LoginOrSignupModal.module.scss';

export const LoginOrSignupModalContent: React.FC = () => {
  const navigate = useNavigate();
  const { hideModal } = React.useContext(GlobalModalContext);

  function goToSignup() {
    navigate('/signup');
    hideModal();
  }
  
  return (
    <div>
      <div className={styles.LoginOrSignupModal__formWrapper}>
        <LoginForm
          secondaryAction={
            <Button type="link" onClick={goToSignup}>Signup</Button>
          }
        />
      </div>
    </div>
  );
}

export function useModal() {
  const { showModal } = React.useContext(GlobalModalContext);
  return {
    showModal: (props = {}) => showModal(<LoginOrSignupModalContent {...props} />),
  };
}

export const LoginOrSignupModalWrapper = (
  { children, onClick, ...props }: {
    children: React.ReactElement;
    onClick: (e: React.MouseEvent<any>) => void;
  }
) => {

  const { showModal } = useModalWithAuthCheck(
    useModal().showModal,
    onClick,
  );

  return React.cloneElement(children, {
    ...props,
    onClick: showModal,
  });
}
