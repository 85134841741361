import * as React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Affix, Col, Row } from 'antd';
import { Helmet } from 'react-helmet';
import { DealDetails } from 'src/components/DealDetails';

import { getDeal, IDeal } from 'src/api/deals';

import styles from './DealPage.module.scss';
import { DealActionsPanel } from 'src/components/DealActionsPanel';
import { DealComments } from 'src/components/DealComments';
import { PageLoading } from 'src/components/PageLoading';

export const DealPage: React.FC = () => {
  const { dealId } = useParams();
  // TODO consider handling case where dealId is undefined
  const { isLoading, error, data } = useQuery(
    `getDealById-${dealId}`, () => getDeal(dealId!));

  if (isLoading) return <div className={styles.DealPage__loading}><PageLoading /></div>;
  if (error) return null;
  
  const deal: IDeal | undefined = data;
  if (!deal) return null;

  return (
    <>
      <Helmet>
        <meta property="og:url" content={window.location.href} />,
        <meta property="og:title" content={deal.title} />,
        <meta property="og:image" content={deal.imageUrls[0]} />,
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={deal.title} />,
        <meta property="twitter:description" content={deal.descriptionText} />,
        <meta property="twitter:image" content={deal.imageUrls[0]} />,
      </Helmet>
      <Row className={styles.DealPage__row} gutter={24}>
        <Col xs={24} sm={24} md={{ offset: 3, span: 12 }}>
          <div className={styles.DealPage__detailsWrapper}>
            <DealDetails
              createdAt={deal.createdAt}
              description={deal.descriptionHtml}
              hotRating={deal.hotRating}
              poster={deal.creator}
              title={deal.title}
            />
          </div>
          <DealComments comments={[]} />
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Affix offsetTop={64}>
            <div className={styles.DealPage__panelWrapper}>
              <DealActionsPanel
                currency={deal.currency}
                dealPrice={deal.dealPrice}
                dealUrl={deal.dealUrl}
                imageUrls={deal.imageUrls}
                rating={deal.rating}
                retailer={deal.retailer}
                retailPrice={deal.retailPrice}
                title={deal.title}
              />
            </div>
          </Affix>
        </Col>
      </Row>
    </>
  );
}