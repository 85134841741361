import * as React from 'react';
import { Button, Comment, Form } from 'antd';
import { InlineProfileAvatar } from 'src/components/InlineProfileAvatar';
import { RichEditor } from 'src/components/RichEditor';
import { LoginOrSignupModalWrapper } from 'src/components/GlobalModal/LoginOrSignupModal';

import { ICommentReplyProps } from './CommentReply.types';
import styles from './CommentReply.module.scss';

const Editor: React.FC = () => {
  // TODO handle blocking html action submit
  return (
    <Form className={styles.CommentReply__editor}>
      <Form.Item>
        <RichEditor placeholder="Write a comment" />
      </Form.Item>
      <Form.Item className={styles.CommentReply__editorActions}>
        <LoginOrSignupModalWrapper onClick={() => {}}>
          <Button htmlType="submit" type="primary">
            Add Comment
          </Button>
        </LoginOrSignupModalWrapper>
      </Form.Item>
    </Form>
  );
};

export const CommentReply: React.FC<ICommentReplyProps> = (props) => {
  return (
    <Comment
      className={styles.CommentReply}
      avatar={
        <InlineProfileAvatar profileImageUrl={props.poster.profileImageUrl} />
      }
      content={<Editor />}
    />
  )
}