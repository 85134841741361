import { Store } from 'src/redux/app.store';
import { AuthUser, AuthStore } from './auth.store';
import { ERequestStatus } from 'src/api';

function getAuthStore(state: Store): AuthStore {
  return state.auth;
}

export function getInitAppStatus(state: Store): ERequestStatus {
  return getAuthStore(state).initAppStatus;
}

export function getCurrentUser(state: Store): AuthUser | undefined {
  return getAuthStore(state).currentUser;
}

export function getloginStatus(state: Store): ERequestStatus {
  return getAuthStore(state).loginStatus;
}

export function getIsLoggedIn(state: Store): boolean {
  return getAuthStore(state).isLoggedIn;
}
