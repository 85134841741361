import * as React from 'react';
import { Card, Col, Row } from 'antd';

import { CardHeader } from 'src/components/CardHeader';
import { RequestUpdatePasswordForm } from 'src/components/forms/RequestUpdatePasswordForm';
// import { UpdatePasswordForm } from 'src/components/forms/UpdatePasswordForm';

import styles from './UpdatePasswordPage.module.scss';

export const UpdatePasswordPage: React.FC = () => {
  // TODO on query param reset id and valid id, show update password form
  return (
    <div>
      <Row>
        <Col xs={24} sm={{ offset: 6, span: 12 }}>
          <Card className={styles.UpdatePasswordPage__panel}>
            <CardHeader>Update Password</CardHeader>
            <RequestUpdatePasswordForm />
            {/* <UpdatePasswordForm /> */}
          </Card>
        </Col>
      </Row>
    </div>
  );
}