import { IPoster, IUser } from 'src/api/users';

import { api } from '.';

export type THotRating = 1 | 2 | 3 | 4 | 5;

// some attributes of deal stored on the backend
// will never be sent to the frontend
export interface IDeal {
  id: string;
  title: string;
  dealUrl: string;
  currency: string;
  dealPrice: number;
  retailPrice: number;
  descriptionHtml: string;
  descriptionText: string;
  imageUrls: string[];
  retailer: string;

  rating: number;
  hotRating: THotRating;

  updater: IUser;
  creator: IUser;

  createdAt: string;
  updatedAt: string;
}

export interface IListDeal {
  id: string;
  title: string;
  imageUrls: string[];
  dealPrice: number;
  retailPrice: number;
  currency: string;
  poster: IPoster;
  dealUrl: string;
  description: string;
  retailer: string;
  rating: number,
  commentsCount: number,
  hotRating: 1 | 2 | 3 | 4 | 5,
}

export async function getDeal(dealId: string): Promise<IDeal> {
  const res = await api.v1.get<IDeal>(`/deals/${dealId}`);
  return res.data;
}

export async function getDealList() {
  const res = await api.v1.get<IListDeal[]>('/deals');
  return res.data;
}

// this could be typed better
export async function postDeal(payload: Partial<IDeal>) {
  const res = await api.v1.post<IDeal>('/deals', payload);
  return res.data;
}
