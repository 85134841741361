import { all, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import { actions as authActions } from 'src/redux/auth/auth.reducer';
import { api, ERequestStatus, resetJwt, setJwt } from 'src/api';
import { history } from 'src/redux/app.store';
import { getInitAppStatus, getIsLoggedIn } from './auth.selector';

function* initUserSaga() {
  yield takeEvery(authActions.initUserApp.started, fetchCurrentUser);
  // yield takeEvery(authActions.initUserApp.started, fetchUserResources);
}

function* fetchJwt() {
  const res: {
    data: {
      token: string;
    }
  } = yield api.v1.get('/auth/token');
  setJwt(res.data.token);
}

function* fetchCurrentUser() {
  try {
    yield fetchJwt();
    // fetch user
    yield all([
      // put(authActions.fetchUser.started()),
    ]);

    yield all([
      // take(authActions.fetchUser.done),
    ]);
    
    yield put(authActions.initUserApp.done({}));
  } catch(e: any) {
    console.error(e);
    yield put(authActions.initUserApp.failed(e))
  }
}

// example fetch
// function* fetchUserResources() {
//   try {
//     const jwt: string = '';
//     if (!jwt) throw new Error('No User JWT!');
//     // fetch user
//     // fetch workspaces
//     yield all([
//       put(authActions.fetchUser.started()),
//     ]);

//     yield all([
//       take(authActions.fetchUser.done),
//     ]);
    
//     yield put(authActions.initUserApp.done({}));
//   } catch(e: any) {
//     console.error(e);
//     yield put(authActions.initUserApp.failed(e))
//   }
// }

function* fetchUserSaga() {
  while(true) {
    yield take(authActions.fetchUser.started);
    const res: { data: any } = yield api.v1.get('/users/me');
    yield put(authActions.fetchUser.done({ result: res.data }));
  }
}

function goToHomePage() {
  if (window.location.pathname.indexOf('/extension') === 0) {
    // redirect to extension home page
    history.push('/extension');
  } else {
    // redirect to home page
    history.push('/');
  }
}

function* loginSaga(): any {
  while(true) {
    try {
      const action = yield take(authActions.login.started);
      const { username, password }: {
        username: string;
        password: string;
      } = action.payload;

      // post login
      const res: {
        data: {
          token: string;
        }
      } = yield api.v1.post('/auth/login', {
        username,
        password
      });
      // store token
      setJwt(res.data.token);
      yield put(authActions.login.done({ params: { username, password } }));
      goToHomePage();
    } catch(e: any) {
      console.error(e);
      yield put(authActions.login.failed(e));
      // handle bad/expired jwt token
      // TODO make message better
      // yield put(authActions.logout);
      // message.info('Your session expired. Please login again.');
    }
  }
}

function* logoutSaga() {
  while(true) {
    yield take(authActions.logout.started);

    resetJwt();
    Cookies.remove('loggedIn');
    // ignore if this request fails
    api.v1.post('/auth/logout');
    yield put(authActions.logout.done({}));
    // redirect to home page
    goToHomePage();
  }
}

// TODO handle jwt expiration
function* checkLoggedInCookieSaga(): any {
  while(true) {
    yield delay(500);
    const isLoggedInCookie = !!Cookies.get('loggedIn');
    const isLoggedIn = yield select(getIsLoggedIn);

    if (isLoggedIn !== isLoggedInCookie) {
      if (isLoggedInCookie) {
        const initAppStatus = yield select(getInitAppStatus);
        if (initAppStatus === ERequestStatus.DEFAULT) {
          // fake login
          yield put(authActions.login.done({
            params: {
              username: '',
              password: '',
            },
          }));
          // currently going to the homepage after login.done kicks off
          // authActions.initUserApp.started
          goToHomePage();
        }
      } else {
        yield put(authActions.logout.started());
      }
    }
  }
}


export default function* authSaga() {
  yield all([
    fetchUserSaga(),
    initUserSaga(),
    loginSaga(),
    logoutSaga(),
    checkLoggedInCookieSaga(),
  ]);
};
