import * as React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export const PageLoading: React.FC = () => {
  return (
    <Spin indicator={loadingIcon} />
  );
}