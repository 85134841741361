import * as React from 'react';
import { message, Affix, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CardHeader } from 'src/components/CardHeader';
import { IDeal, postDeal } from 'src/api/deals';

import { PostDealForm } from './PostDealForm';
import { encodeDealTitle } from '../DealGrid/DealCard/utils';

import { TPostDealFormItemNames } from './PostDealForm/PostDealForm.types';

export const PostDealPanel: React.FC = () => {
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = React.useState<TPostDealFormItemNames|undefined>();
  
  function onFieldFocus(fieldName: TPostDealFormItemNames) {
    setFocusedField(fieldName);
  }

  function onSubmitDealSuccess(deal: IDeal) {
    // alert success deal created
    message.success('Deal successfully posted! 🎉');
    // transition to deal page
    const dealId = deal.id;
    navigate(`/deal/${dealId}/${encodeDealTitle(deal.title)}`);
  }

  return (
    <Row gutter={24}>
      <Col md={8}>
        <Affix offsetTop={96}>
          <Card>
            {focusedField}
          </Card>
        </Affix>
      </Col>
      <Col md={16}>
        <Card>
          <CardHeader>Share a deal</CardHeader>
          <PostDealForm
            action={postDeal}
            onFieldFocus={onFieldFocus}
            onSuccess={onSubmitDealSuccess}
          />
        </Card>
      </Col>
    </Row>
  );
}