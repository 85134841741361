import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { IAccountFormProps } from './AccountForm.types';

import styles from './AccountForm.module.scss';

export const AccountForm: React.FC<IAccountFormProps> = (props) => {
  return (
    <Form
      labelCol={{
        xs: { span: 24 },
        sm: { span: 8 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 16 },
      }}
    >
      <Form.Item
        label="First name"
        name="firstName"
        rules={[
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="lastName"
        rules={[
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        extra="We currently do not support updating account emails"
        label="Email"
        name="email"
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter your email' },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Username"
        name="username"
        rules={[
          { required: true, message: 'Please enter your username' },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.AccountForm__updatePassword}>
        <Link
          to={`/update-password?email=${props.email}`}
        >Update Password</Link>
      </div>
      <Form.Item wrapperCol={{ xs: 24 }} className={styles.AccountForm__submitBtnWrapper}>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}