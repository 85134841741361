import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card } from 'antd';
import {
  SEARCH_QP_KEY,
  DAYS_BEFORE_QP_KEY,
  MAX_PRICE_QP_KEY,
  MIN_PRICE_QP_KEY,
  SORT_QP_KEY,
} from 'src/constants/search';

import { SearchFilterForm } from './SearchFilterForm';
import { EDaysBeforeOptionValue } from './SearchFilterForm/DaysBeforeSelect/DaysBeforeSelect.types';
import { ESortOptionText } from './SearchFilterForm/SortSelect/SortSelect.types';

import styles from './SearchFilterPanel.module.scss';

export const SearchFilterPanel: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const q = searchParams.get(SEARCH_QP_KEY);

  function onDaysBeforeChange(val: EDaysBeforeOptionValue): void {
    const daysBefore = val as any;
    setSearchParams({ ...queryParams, [DAYS_BEFORE_QP_KEY]: daysBefore });
  }

  function onPriceChange([minPrice, maxPrice]: [string|undefined, string|undefined]): void {
    const queryParamsCopy = {...queryParams};
    if (maxPrice) {
      queryParamsCopy[MAX_PRICE_QP_KEY] = maxPrice;
    } else {
      delete queryParamsCopy[MAX_PRICE_QP_KEY];
    }

    if (minPrice) {
      queryParamsCopy[MIN_PRICE_QP_KEY] = minPrice;
    } else {
      delete queryParamsCopy[MIN_PRICE_QP_KEY];
    }

    setSearchParams(queryParamsCopy);
  }

  function onSortChange(val: keyof typeof ESortOptionText): void {
    setSearchParams({ ...queryParams, [SORT_QP_KEY]: val });
  }

  return (
    <Card className={styles.SearchFilterPanel}>
      <SearchFilterForm
        daysBefore={queryParams[DAYS_BEFORE_QP_KEY]}
        minPrice={queryParams[MIN_PRICE_QP_KEY]}
        maxPrice={queryParams[MAX_PRICE_QP_KEY]}
        sort={queryParams[SORT_QP_KEY]}

        resetLinkTo={`/search?q=${q || ''}`}

        onDaysBeforeChange={onDaysBeforeChange}
        onPriceChange={onPriceChange}
        onSortChange={onSortChange}
      />
    </Card>
  )
}