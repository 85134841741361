import * as React from 'react';
import { useMutation } from 'react-query';
import { Button, Form, Input } from 'antd';

import { INoResultsSubscribeFormProps } from './NoResultsSubscribeForm.types';

export const NoResultsSubscribeForm: React.FC<INoResultsSubscribeFormProps> = (props) => {
  const {
    isLoading,
    mutate, 
  } = useMutation(props.action, {
    onError: (err: any) => {
      // TODO handle error
      // props.onFail && props.onFail(err);
    },
    onSuccess: props.onSuccess,
  });

  function onFinish(values: {
    email: string;
  }) {
    mutate(values);
  }
  
  return (
    <Form
      labelCol={{
        span: 8,
      }}
      layout={props.layout}
      onFinish={onFinish}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter your email' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}