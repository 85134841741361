import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';
import { COMPANY_DESCRIPTION, COMPANY_NAME, PROD_DOMAIN } from 'src/app.constants';
import { Logo } from 'src/components/Logo';

import styles from './Footer.module.scss';

export const Footer: React.FC = function() {
  return (
    <Layout.Footer className={styles.Footer}>
      <Row>
        <Col xs={24} md={{ span: 18, offset: 3 }}>
          <Row className={styles.Footer__info} gutter={24}>
            <Col xs={24} md={6}>
              <div><Logo size={64} /></div>
              <div className={styles.Footer__description}>
                {COMPANY_DESCRIPTION} 
              </div>
            </Col>
            <Col className={styles.Footer__linkColumn} xs={24} md={6}>
              <div>
                <Link to="/terms-of-service">Terms of service</Link>
              </div>
              <div>
                <a href={`mailto:contact@${PROD_DOMAIN}`}>Contact us</a>
              </div>
            </Col>
          </Row>
          <div className={styles.Footer__copyright}>
            © {new Date().getFullYear()} {COMPANY_NAME}. All rights reserved.
          </div>
        </Col>
      </Row>
    </Layout.Footer>
  )
}
