export enum EDaysBeforeOptionText {
  'anyTime' = 'Any time',
  'lastDay' = 'Past 24 hours',
  'lastWeek' = 'Past week',
  'lastTwoWeeks' = 'Past 2 weeks',
  'lastMonth' = 'Past month',
  'lastThreeMonths' = 'Past 3 months',
  'lastSixMonths' = 'Past 6 months',
  'lastYear' = 'Past year',
}

export enum EDaysBeforeOptionValue {
  'anyTime' = 0,
  'lastDay' = 1,
  'lastWeek' = 7,
  'lastTwoWeeks' = 14,
  'lastMonth' = 30,
  'lastThreeMonths' = 90,
  'lastSixMonths' = 180,
  'lastYear' = 365,
}

export interface IDaysBeforeSelectProps {
  onChange: (val: EDaysBeforeOptionValue) => void;
}