import * as React from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'antd';

import { getSearch } from 'src/api/search';
import { DealGrid } from 'src/components/DealGrid';
import { PageLoading } from 'src/components/PageLoading';

import styles from './LandingPage.module.scss';

export const LandingPage: React.FC = () => {
  const { isLoading, error, data: deals } = useQuery('getLandingDealList', () => getSearch());

  // TODO make this better
  if (isLoading) return <div className={styles.LandingPage__loading}><PageLoading /></div>;
  if (error) return null;

  // TODO update with axios
  if (!deals) return null;

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={{ offset: 4, span: 16 }}>
          <div className={styles.LandingPage__message}>
            Find the sweetest deals with Melon!
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.LandingPage__gridCol} xs={24} sm={24} md={{ offset: 4, span: 16 }}>
          <DealGrid deals={deals} />
        </Col>
      </Row>
    </div>
  );
}
