import * as React from 'react';
import { Button, Card } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { formatMoney } from 'src/utils/currency';

import { IDealActionsPanelProps } from './DealActionsPanel.types';
import styles from './DealActionsPanel.module.scss';
import { UpVoteButton } from '../UpVoteButton';
import { DownVoteButton } from '../DownVoteButton';
import { useModal } from '../GlobalModal/LoginOrSignupModal';
import { useModalWithAuthCheck } from '../GlobalModal';

// TODO carousel - style bottom dots, add back/forward buttons
//   or grab a 3rd party carousel lib
// TODO handle when current user has already voted
// TODO update sharing with affiliate user id
// TODO add deal favoriting
// TODO add deal reporting
export const DealActionsPanel: React.FC<IDealActionsPanelProps> = (props) => {
  function downVote() {

  }

  function upVote() {

  }

  const showModalOrDownVote = useModalWithAuthCheck(
    useModal().showModal,
    downVote,
  ).showModal;

  const showModalOrUpVote = useModalWithAuthCheck(
    useModal().showModal,
    upVote,
  ).showModal;

  return (
    <Card className={styles.DealActionsPanel}>
      <Carousel showArrows={true} showStatus={false} showThumbs={false}>
        {props.imageUrls.map((url, i) =>
          <div key={i} className={styles.DealActionsPanel__productImageWrapper}>
            <img
              className={styles.DealActionsPanel__productImage}
              src={url}
              alt="product_image"
            />
          </div>
        )}
      </Carousel>
      <a href={props.dealUrl} target="_blank" rel="noreferrer">
        <Button className={styles.DealActionsPanel__linkButton} type="primary" size="large">Get deal</Button>
      </a>
      <div className={styles.DealActionsPanel__retailer}>
        {props.retailer}
      </div>
      <div className={styles.DealActionsPanel__dealPrice}>
        {formatMoney(props.dealPrice, props.currency)}
      </div>
      {!!props.retailPrice && <div className={styles.DealActionsPanel__retailPrice}>
        {formatMoney(props.retailPrice, props.currency)}
      </div>}
      <div className={styles.DealActionsPanel__rating}>
        <UpVoteButton
          className={styles.DealActionsPanel__voteBtn}
          onClick={showModalOrUpVote}
        />
        {!!props.rating && props.rating}
        <DownVoteButton
          className={styles.DealActionsPanel__voteBtn}
          onClick={showModalOrDownVote}
        />
      </div>
      <div className={styles.DealActionsPanel__share}>
        <FacebookShareButton
          className={styles.DealActionsPanel__shareBtn}
          url={window.location.href}
          quote={props.title}
        >
          <FacebookIcon size={36} />
        </FacebookShareButton>
        <TwitterShareButton
          className={styles.DealActionsPanel__shareBtn}
          url={window.location.href}
        >
          <TwitterIcon size={36} />
        </TwitterShareButton>
        <EmailShareButton
          className={styles.DealActionsPanel__shareBtn}
          url={window.location.href}
        >
          <EmailIcon size={36} />
        </EmailShareButton>
      </div>
    </Card>
  );
};