import * as React from 'react';
import { Card, Comment, List } from 'antd';
import { InlineProfileAvatar } from 'src/components/InlineProfileAvatar';

import { IDealCommentsProps } from './DealComments.types';
import { CommentReply } from './CommentReply';

// TODO use props.currentUser to determine if user can comment
// TODO handle deletedAt and hiddenAt
// TODO add comment reporting
export const DealComments: React.FC<IDealCommentsProps> = (props) => {
  return (
    <Card>
      {!!props.comments.length &&
        <List
          itemLayout="horizontal"
          dataSource={props.comments}
          renderItem={comment => (
            <Comment
              key={comment.id}
              author={comment.poster.username}
              avatar={
                <InlineProfileAvatar profileImageUrl={comment.poster.profileImageUrl} />
              }
              content={
                <p>
                  {comment.value}
                </p>
              }
            />
          )}
        />
      }
      <CommentReply
        poster={{
          profileImageUrl: 'https://joeschmoe.io/api/v1/random',
        }}
      />
    </Card>
  );
};