import { api } from '.';

const prefix = '/sub';

export async function postPreSignup(payload: { email: string }) {
  const res = await api.v1.post(`${prefix}/pre-signup`, payload);
  return res.data;
}

// data include search query and filters
export async function postSubscribeNoSearchResults(payload: {
  email: string;
  data: any;
}) {
  const res = await api.v1.post(`${prefix}/no-search-results`, payload);
  return res.data;
}
