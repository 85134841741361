import * as React from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import {
  DeltaStatic,
  Sources,
} from 'quill';
import classNames from 'classnames';
import 'react-quill/dist/quill.snow.css';

import { IRichEditorProps  } from './RichEditor.types';

import styles from './RichEditor.module.scss';

export const RichEditor: React.FC<IRichEditorProps> = (props) => {
  function onChange(
    content: string,
    _delta: DeltaStatic,
    _source: Sources,
    editor: UnprivilegedEditor) {
    props.onChange && props.onChange({
      content,
      text: editor.getText(),
    });
  }

  return (
    <ReactQuill
      className={classNames(styles.RichEditor__editor, styles.RichEditor__content)}
      // setting value gives errors
      // see https://github.com/quilljs/quill/issues/1940
      defaultValue={props.value?.content}
      onChange={onChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      theme="snow"
    />
  );
}