import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { message } from 'antd';
import { actions as authActions } from 'src/redux/auth/auth.reducer';

export const LogoutPage: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(authActions.logout.started());
    message.success(
      <span>
        You've successfully logged out! <Link to="/login">
          Click here to login.
        </Link>
      </span>
    );
  }, [dispatch]);
  return (
    <Navigate to="/" />
  );
};
