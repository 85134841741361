import * as React from 'react';
import {
  useDispatch,
} from 'react-redux'
import { Card, Col, Row } from 'antd';
import { CardHeader } from 'src/components/CardHeader';
import LoginForm from 'src/components/forms/LoginForm';

import styles from './LoginPage.module.scss';

import { actions as authActions } from 'src/redux/auth/auth.reducer';


export const LoginPage: React.FC = function(): React.ReactElement {
  const dispatch = useDispatch();
  const onFinished = React.useCallback(
    function({ username, password } : {
      username: string;
      password: string;
    }) {
      dispatch(authActions.login.started({
        username,
        password,
      }));
    },
    [dispatch]
  );

  return (
    <div>
      <Row gutter={16}>
        <Col span={8} offset={8}>
          <Card className={styles.LoginPage__panel}>
            <CardHeader>Login</CardHeader>
            <LoginForm
              onFinish={onFinished}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
