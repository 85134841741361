import Cookies from 'js-cookie';
import { ERequestStatus } from 'src/api';

export interface AuthUser {
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
}

export interface Auth {
  initAppStatus: ERequestStatus;
  currentUser: AuthUser | undefined;
  loginStatus: ERequestStatus;
  isLoggedIn: boolean;
}

export type AuthStore = Auth;

export const initialAuthStore: AuthStore = {
  initAppStatus: ERequestStatus.DEFAULT,
  currentUser: undefined,
  loginStatus: ERequestStatus.DEFAULT,
  isLoggedIn: !!Cookies.get('loggedIn'),
};
