import { accountUser } from './mockData';

export interface IUser {
  username: string;
  profileImageUrl: string | null;
}

export interface IPoster {
  username: string;
  profileImageUrl: string | null;
}

export function getUser(username: string) {
}

export function getAccountUser() {
  return Promise.resolve(accountUser);
}