import { all, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'redux-first-history';
import { Action } from 'redux';
import authSaga from 'src/redux/auth/auth.saga';

function* scrollToTopOnPush() {
  yield takeEvery(LOCATION_CHANGE, function(action: Action<any>) {
    // @ts-ignore example shape: https://github.com/salvoravida/redux-first-history/blob/a3f8db714d6fa46a5b38e488a06d3d30382d6977/__tests__/reducer.test.ts#L44
    if (action.payload.action === 'PUSH') {
      // scroll to the top
      window.scrollTo(0, 0);
    }
  });
}

export default function* appSaga() {
  yield all([
    authSaga(),
    scrollToTopOnPush(),
  ]);
};
