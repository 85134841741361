import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import styles from './RequestUpdatePasswordForm.module.scss';

export const RequestUpdatePasswordForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const email: string | null = searchParams.get('email');

  // TODO update password rules
  return (
    <Form>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter your email' },
        ]}
      >
        <Input defaultValue={email || ''} />
      </Form.Item>
      <Form.Item className={styles.RequestUpdatePasswordForm__submitBtnWrapper}>
        <Button type="primary" htmlType="submit">
          Send update password email 
        </Button>
      </Form.Item>
    </Form>
  );
}