import axios, { AxiosRequestConfig, HeadersDefaults } from 'axios';

export let jwt: string;

export enum EApiVersion {
  v1 = 'v1',
}

export enum ERequestStatus {
  DEFAULT = 'DEFAULT',
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export const api = {
  [EApiVersion.v1]: axios.create({
    baseURL: '/api',
  }),
};

export function setJwt(token: string): void {
  jwt = token;
}

export function resetJwt(): void {
  setJwt('');
}

function addAuthHeaderToConfig(config: AxiosRequestConfig) {
  return {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...((config.headers as Partial<HeadersDefaults>).common),
        Authorization: jwt ? `Bearer ${jwt}` : undefined,
      }
    }
  };
}

Object.entries(api).forEach(([_, versionedApi]) => {
  versionedApi.interceptors.request.use(addAuthHeaderToConfig);
});
