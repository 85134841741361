import * as React from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'antd';

import { getSearch } from 'src/api/search';
import { DealGrid } from 'src/components/DealGrid';

import styles from './HomePage.module.scss';

export const HomePage: React.FC = () => {
  const { isLoading, error, data: deals } = useQuery('getHomeDealList', () => getSearch());

  // TODO make this better
  if (isLoading) return null;
  if (error) return null;

  // TODO update with axios
  if (!deals) return null;

  return (
    <div>
      <Row>
        <Col className={styles.LandingPage__gridCol} xs={24} sm={24} md={{ offset: 4, span: 16 }}>
          <DealGrid deals={deals} />
        </Col>
      </Row>
    </div>
  );
}
