import * as React from 'react';

import { ImportImage } from 'src/components/ImportImage';

// TODO convert to svg
import LogoImage from 'src/assets/images/logo_256.png';
import { ILogoProps } from './Logo.types';

export const Logo: React.FC<ILogoProps> = (props) => {
  return (
    <ImportImage src={LogoImage} size={props.size} />
  );
}