import axios from 'axios';
import { api } from '.';

const CLOUDINARY_API = 'https://api.cloudinary.com/v1_1';

export interface ICloudinaryCreds {
  signature: string;
  apiKey: string;
  timestamp: number;
  cloudName: string;
  uploadPreset: string;
}

const prefix = '/cloudinary';
export async function getCreds(): Promise<ICloudinaryCreds> {
  return (await api.v1.get(`${prefix}/creds`)).data;
}

export function upload(cloudName: string, data: any) {
  return axios.post(`${CLOUDINARY_API}/${cloudName}/upload`, data, {
    headers: {
      'Content-Type': 'multipart/formdata',
    }
  });
}