import * as React from 'react';
import { LikeOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { IUpVoteButtonProps } from './UpVoteButton.types';

import styles from './UpVoteButton.module.scss';

export const UpVoteButton: React.FC<IUpVoteButtonProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <button
      className={classNames(styles.UpVoteButton, className)}
      {...rest}
    >
      <LikeOutlined className={styles.UpVoteButton__icon} />
    </button>
  );
}