import * as React from 'react';
import { Button, Form, Input } from 'antd';
import { useMutation } from 'react-query';
import isNumeric from 'validator/es/lib/isNumeric';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { IDeal } from 'src/api/deals';
import { RichEditor } from 'src/components/RichEditor';
import { DealImageFormItem } from '../DealImageFormItem';

import {
  TPostDealFormItemNames,
  IPostDealFormProps,
  TPostDealPayloadKeys,
} from './PostDealForm.types';

import styles from './PostDealForm.module.scss';
import { uploadImages } from 'src/utils/cloudinary';
import { getCreds, ICloudinaryCreds } from 'src/api/cloudinary';

export const PostDealForm: React.FC<IPostDealFormProps> = (props) => {
  const {
    isLoading,
    mutate, 
  } = useMutation(props.action, {
    onError: (err: any) => {
      props.onFail && props.onFail(err);
    },
    onSuccess: (res: IDeal) => {
      props.onSuccess(res);
    },
  });

  function makeOnFieldFocus(fieldName: TPostDealFormItemNames): (() => void) {
    return () => {
      props.onFieldFocus(fieldName);
    };
  }

  async function onFinish(values: Record<TPostDealFormItemNames, any>) {
    const photoFiles = values.photos.map((photo: any) => photo.originFileObj);
    
    try {
      const creds: ICloudinaryCreds = await getCreds();
      const resDataList = await uploadImages(creds, photoFiles);

      const payload: Record<TPostDealPayloadKeys, any> = {
        title: values.title,
        dealPrice: values.dealPrice,
        imageUrls: resDataList.map((data) => data.secure_url),
        originalUrl: values.url,
        retailPrice: values.retailPrice,
        descriptionHtml: values.description.content,
        descriptionText: values.description.text,
      };
      mutate(payload);
    } catch (err: any) {
      console.error(err);
      // TODO handle error
      // let user know via toast?
    }
  }

  function onFinishFailed(errorInfo: ValidateErrorEntity<{ [name: string]: any; }>) {
    console.log('error info: ', errorInfo)
  }
  
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Deal URL"
        name="url"
        rules={[
          { type: 'url', message: 'Please enter a valid url' },
          { required: true, message: 'Please enter a deal url' },
        ]}
      >
        <Input type="url" onFocus={makeOnFieldFocus('url')} />
      </Form.Item>
      <Form.Item
        label="Deal title"
        name="title"
        rules={[
          { required: true, message: 'Please give a helpful title' },
        ]}
      >
        <Input onFocus={makeOnFieldFocus('title')} />
      </Form.Item>
      <Form.Item
        label="Deal price"
        name="dealPrice"
        rules={[
          {
            message: 'Please enter a valid price',
            required: true,
            validator: (_, value) =>
              isNumeric(value || '') ? Promise.resolve() : Promise.reject(),
          }
        ]}
      >
        <Input
          className={styles.PostDealForm__priceInput}
          inputMode="numeric"
          onFocus={makeOnFieldFocus('dealPrice')}
          prefix="$"
          step="0.01"
          type="number"
          min="0"
        />
      </Form.Item>
      <Form.Item
        label="Retail price"
        name="retailPrice"
        rules={[
          {
            message: 'Please enter a valid price',
            validator: (_, value) =>
              (!value || isNumeric(value)) ? Promise.resolve() : Promise.reject(),
          },
        ]}
      >
        <Input
          className={styles.PostDealForm__priceInput}
          inputMode="numeric"
          onFocus={makeOnFieldFocus('retailPrice')}
          prefix="$"
          step="0.01"
          type="number"
          min="0"
        />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          { required: true, message: 'Please give a helpful description' },
        ]}
      >
        <RichEditor
          onFocus={makeOnFieldFocus('description')}
          placeholder="Describe your deal..."   
        />
      </Form.Item>
      <div onMouseEnter={makeOnFieldFocus('photos')}>
        <Form.Item
          label="Photos"
          name="photos"
          rules={[
            { required: true, message: 'Please upload some photos' },
          ]}
        >
          <DealImageFormItem />
        </Form.Item>
      </div>
      <Form.Item className={styles.PostDealForm__submitBtnWrapper}>
        <Button
          htmlType="submit"
          loading={isLoading}
          onMouseEnter={makeOnFieldFocus('submit')}
          type="primary"
        >
          {!isLoading && 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  );
}