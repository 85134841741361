import * as React from 'react';
import {
  useSelector,
} from 'react-redux';
import { Form, Input, Button } from 'antd';

import { getloginStatus } from 'src/redux/auth/auth.selector';
import { ERequestStatus } from 'src/api';
import { ILoginFormProps } from './LoginForm.types';

import styles from './LoginForm.module.scss';

export const LoginForm: React.FC<ILoginFormProps> = (props): React.ReactElement => {
  const loginStatus = useSelector(getloginStatus);
  const hasLoginError = loginStatus === ERequestStatus.FAILED;

  return (
    <Form
      layout={props.layout}
      onFinish={props.onFinish}
      onFinishFailed={props.onFinishFailed}
    >
      <Form.Item
        // this has to be username due to backend passport-local logic
        // but this should be checked against the user's email column value
        name="username"
        validateStatus={hasLoginError ? 'error' : undefined}
        rules={[
          { type: 'email', message: 'Please enter a valid email' },
          { required: true, message: 'Please enter your email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        validateStatus={hasLoginError ? 'error' : undefined}
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      {hasLoginError && <div className={styles.LoginForm__error}>Login failed. Please try again.</div>}
      <div className={styles.LoginForm__actions}>
        {props.secondaryAction}
        <Form.Item className={styles.LoginForm__submitBtnWrapper}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loginStatus === ERequestStatus.PENDING}
          >
            Login
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

LoginForm.defaultProps = {
  layout: 'horizontal',
};

export default React.memo(LoginForm);
