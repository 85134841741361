import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'antd';

import { postPreSignup } from 'src/api/subscribe';

import SignupForm from 'src/components/forms/SignupForm';
import { CardHeader } from 'src/components/CardHeader';

import styles from './SignupPage.module.scss';

function postSignup(values: {
  email: string;
}): Promise<any> {
  return postPreSignup(values);
}

export const SignupPage: React.FC = (): React.ReactElement => {
  const [signupSuccessful, setSignupSuccessful] = React.useState(false);

  function onSuccess() {
    setSignupSuccessful(true);
  }

  return (
    <div>
      <Row gutter={16}>
        <Col span={8} offset={8}>
          <Card className={styles.SignupPage__panel}>
            <CardHeader>Signup</CardHeader>
            {signupSuccessful ?
              <div>
                <div>
                  🎉 Thank you for signing up! We'll notify you once we're ready to support user accounts!
                </div>
                <Link to="/">Browse more deals</Link>
              </div>
              :
              <SignupForm
                action={postSignup}
                onSuccess={onSuccess}
              />
            }
          </Card>
        </Col>
      </Row>
    </div>
  );
};
