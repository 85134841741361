import * as React from 'react';
import { Button, Card, Col, Row, Upload } from 'antd';
import { CardHeader } from 'src/components/CardHeader';
import { InlineProfileAvatar } from 'src/components/InlineProfileAvatar';
import { AccountForm } from './AccountForm';

import { IAccountDetailsProps } from './AccountDetails.types';

import styles from './AccountDetails.module.scss';

export const AccountDetails: React.FC<IAccountDetailsProps> = (props) => {
  const { user } = props;
  
  return (
    <Card>
      <CardHeader>Account Info</CardHeader>
      <Row>
        <Col xs={24} md={8}>
          <div className={styles.AccountDetails__profileImage}>
            <InlineProfileAvatar
              profileImageUrl={props.user.profileImageUrl}
              size={128}
            />
            <div className={styles.AccountDetails__actions}>
              <Upload>
                <Button>Update</Button>
              </Upload>
            </div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <AccountForm
            firstName={user.firstName}
            lastName={user.lastName}
            username={user.username}
            email={user.email}
          />
        </Col>
      </Row>
    </Card>
  );
}