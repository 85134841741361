import * as React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';

import styles from 'src/layouts/LoggedInLayout/LoggedInLayout.module.scss';

import { getInitAppStatus } from 'src/redux/auth/auth.selector';
import { ERequestStatus } from 'src/api';
import { actions as authActions } from 'src/redux/auth/auth.reducer';

import { Footer } from 'src/components/Footer';
import { LoggedInHeader } from 'src/components/Header';

import { AccountPage } from 'src/pages/AccountPage';
import { DealPage } from 'src/pages/DealPage';
import { HomePage } from 'src/pages/HomePage';
import { LogoutPage } from 'src/pages/LogoutPage';
import { NftPage } from 'src/pages/NftPage';
import { NotFoundPage } from 'src/pages/NotFoundPage';
import { PostDealPage } from 'src/pages/PostDealPage';
import { SearchPage } from 'src/pages/SearchPage';
import { TermsOfService } from 'src/pages/TermsOfServicePage';
import { UpdatePasswordPage } from 'src/pages/UpdatePasswordPage';

const { Content } = Layout;

export const LoggedInLayout: React.FunctionComponent = function (): React.ReactElement {
  const appStatus = useSelector(getInitAppStatus);
  const doneLoading: boolean = appStatus === ERequestStatus.DONE;
  const dispatch = useDispatch();
  const location = useLocation();
  
  React.useEffect(() => {
    if (appStatus !== ERequestStatus.DONE &&
      appStatus !== ERequestStatus.PENDING) {
      dispatch(authActions.initUserApp.started());
    }
  }, [dispatch, appStatus]);
  if (!doneLoading && location.pathname !== '/logout') return <div>loading</div>;

  return (
    <Layout className={styles.layout}>
      <LoggedInHeader />
      <Content className={styles.content}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin">
            <Route path="comments" />
            <Route path="deals" />
            <Route path="users" />
          </Route>
          <Route path="/deal/:dealId/:dealTitle" element={<DealPage />} />
          <Route path="/me" element={<AccountPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/nft" element={<NftPage />} />
          <Route path="/post-deal" element={<PostDealPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/update-password" element={<UpdatePasswordPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Content>
      <Footer />
    </Layout>
  );
}

export default React.memo(LoggedInLayout);
