import * as React from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'antd';
import { AccountDetails } from 'src/components/AccountDetails';
import { getAccountUser } from 'src/api/users';

import styles from './AccountPage.module.scss';

export const AccountPage: React.FC = () => {
  const { isLoading, error, data } = useQuery(
    'getAccountUser', getAccountUser);

  // TODO make this better
  if (isLoading) return null;
  if (error) return null;
  if (!data) return null;
  
  return (
    <div>
      <Row>
        <Col span={16} offset={4}>
          <div className={styles.AccountPage__panel}>
            <AccountDetails user={data} />
          </div>
        </Col>
      </Row>
    </div>
  );
}