import * as React from 'react';
import { DislikeOutlined  } from '@ant-design/icons';
import classNames from 'classnames';

import { IDownVoteButtonProps } from './DownVoteButton.types';

import styles from './DownVoteButton.module.scss';

export const DownVoteButton: React.FC<IDownVoteButtonProps> = (props) => {
  const { className, ...rest } = props;

  return (
    <button
      className={classNames(styles.DownVoteButton, className)}
      {...rest}
    >
      <DislikeOutlined className={styles.DownVoteButton__icon} />
    </button>
  );
}