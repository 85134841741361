import * as React from 'react';
import { Modal } from 'antd';
import { GlobalModalContext } from './GlobalModal.context';

export const GlobalModalProvider: React.FC = (props) => {
  const [content, setContent] = React.useState<React.ReactNode>();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  function hideModal() {
    setContent(null);
    setIsVisible(false);
  }

  function showModal(content: React.ReactNode) {
    setContent(content);
    setIsVisible(true);
  }

  return (
    <GlobalModalContext.Provider
      value={{
        hideModal,
        showModal,
      }}
    >
      {props.children}
      {isVisible &&
        <Modal
          footer={null}
          onCancel={hideModal}
          visible={isVisible}
        >
          {content}
        </Modal>
      }
    </GlobalModalContext.Provider>
  )
}
