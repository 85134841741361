import * as React from 'react';
import { Col, Row } from 'antd';
import { DealCard } from 'src/components/DealGrid/DealCard';

import {
  IDealGridProps,
  TBreakpointKeys,
  TCardsPerRow,
  TResponsiveCardsPerRow,
} from './DealGrid.types';

function getResponsiveCardsPerRow(
  TResponsiveCardsPerRow: TResponsiveCardsPerRow,
  size: TBreakpointKeys,
): TCardsPerRow | undefined {
  return TResponsiveCardsPerRow[size];
}

function getColSizeFromCardsPerRow(
  cardsPerRow: TCardsPerRow,
): number {
  return 24/cardsPerRow;
}

export const DealGrid: React.FC<IDealGridProps> = (props) => {
  function getColSize(
    size: TBreakpointKeys,
    defaultCardsPerRow: TCardsPerRow,
  ): number {
    const { cardsPerRow } = props;
    if (!cardsPerRow) return getColSizeFromCardsPerRow(defaultCardsPerRow);
    const newCardsPerRow: TCardsPerRow | undefined = typeof cardsPerRow === 'number' ?
      cardsPerRow : getResponsiveCardsPerRow(cardsPerRow, size);
    
    return getColSizeFromCardsPerRow(newCardsPerRow || defaultCardsPerRow);
  }

  const colSizeMap = {
    xs: getColSize('xs', 1),
    sm: getColSize('sm', 2),
    md: getColSize('md', 3),
    lg: getColSize('lg', 3),
    xl: getColSize('xl', 4),
  };

  return (
    <div>
      <Row gutter={[24,24]} wrap>
        {props.deals.map((deal: any) => {
          return (
            <Col
              key={deal.id}
              xs={colSizeMap.xs}
              sm={colSizeMap.sm}
              md={colSizeMap.md}
              lg={colSizeMap.lg}
              xl={colSizeMap.xl}
            >
              <DealCard {...deal} />
            </Col>
          )
        })}
      </Row>
    </div>
  );
}