import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';

import { routerMiddleware } from 'src/redux/app.context';
import createAppReducer from 'src/redux/app.reducer';
import { isDev } from 'src/utils/env';
import { Store } from 'src/redux/app.store';

import appSaga from 'src/redux/app.saga';
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const conditionalCompose: any = isDev ? composeWithDevTools : compose;

export default function configureStore(preloadedState: Store) {
  const store = createStore(
    createAppReducer, // root reducer with router state
    preloadedState,
    conditionalCompose(
      applyMiddleware(
        routerMiddleware, // for dispatching history actions
        // ... other middlewares ...
      ),
      applyMiddleware(sagaMiddleware),
    ),
  )

  // then run the saga
  sagaMiddleware.run(appSaga)

  return store
}
