import configureStore from 'src/redux/configureStore';

import { RouterState } from 'redux-first-history';
import { createReduxHistory } from 'src/redux/app.context';
import { AuthStore, initialAuthStore } from 'src/redux/auth/auth.store';

export interface Store {
  router: RouterState;

  auth: AuthStore;
}

export const initialStore: Omit<Store, 'router'> = {
  auth: initialAuthStore,
};

// TODO initial typing doesn't have router key. using any cast to ignore that error
export const store = configureStore(initialStore as any);
export const getStore = () => store;
export const history = createReduxHistory(store);
export const getHistory = () => history;
