import * as React from 'react';
import { Col, Row } from 'antd';
import { PostDealPanel } from 'src/components/PostDealPanel';

import styles from './PostDealPage.module.scss';

export const PostDealPage: React.FC = () => {
  return (
    <Row className={styles.PostDealPage__row}>
      <Col className={styles.PostDealPage__gridCol} xs={24} sm={24} md={{ offset: 4, span: 16 }}>
        <PostDealPanel />
      </Col>
    </Row>
  );
}