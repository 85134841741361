import * as React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { IInlineProfileAvatarProps } from './InlineProfileAvatar.types';

export const InlineProfileAvatar: React.FC<IInlineProfileAvatarProps> = (props) => {
  // TODO update types for rest
  const { profileImageUrl, ...rest } = props
  
  return (
    <Avatar
      icon={profileImageUrl ? <img src={profileImageUrl} alt="profile_avatar_image" /> : <UserOutlined />}
      {...rest}
    />
  );
}