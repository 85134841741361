import { COMPANY_NAME } from 'src/app.constants';

export const terms: {
  title?: string;
  text: string;
}[] = [
  {
    text: `
      These Terms of Use (the "Terms") govern your access to and use of our websites,
      emails and mobile applications ("${COMPANY_NAME}").
      These Terms also include our Privacy and Cookie Policy.
      By accessing and using ${COMPANY_NAME}, you agree to comply with these Terms.
      If you are using ${COMPANY_NAME} on behalf of a company or other legal entity,
      then “you” also means such company or legal entity and you agree to be bound by these
      Terms even if we have separate agreement with you.
      You may not use ${COMPANY_NAME} if you do not agree to the version of the Terms posted
      on ${COMPANY_NAME} at the time you access ${COMPANY_NAME}.
      (The terms "we" and "us" refer to ${COMPANY_NAME}, Inc., a Delaware corporation.)
      Please note: These Terms require the use of arbitration on an individual basis to resolve disputes,
      rather than jury trials or class actions, and also limit the remedies available to you in the event of a dispute.
    `,
  },
  {
    title: `Eligibility to Use ${COMPANY_NAME}`,
    text: `
      A. To access or use ${COMPANY_NAME}, (1) you must be 13 years of age or older and,
      if under 18 or the age of majority in your jurisdiction,
      your use of ${COMPANY_NAME} must be under the supervision of a parent or guardian
      or other responsible adult and (2) you, or (where applicable) the adult supervising
      your use of ${COMPANY_NAME}, must have the power and authority to enter into these Terms.
      Except for an employer’s authorized use of a Free Employer Account,
      or as otherwise approved by us, ${COMPANY_NAME} is for your personal,
      non-commercial use unless you enter into a separate agreement with us for your commercial use.
      You may not use ${COMPANY_NAME} if we have terminated your account or banned you.
    `,
  },
  {
    title: `Your ${COMPANY_NAME} Account`,
    text: `
      ${COMPANY_NAME} Account. In order for you to create a ${COMPANY_NAME} account,
      we require that you provide a valid email address and set up a password.
      The email you use must be one where we can reach you.
      In the event we cannot correspond with you via this email address,
      your submitted content may be rejected and your account may be disabled.
      Other registration requirements (such as the requirement for individuals 
        to contribute no more than one company review, interview review,
        or salary details of a current or former job per year) may also apply.
        You are entirely responsible for maintaining the confidentiality of your password.
        You agree to notify us immediately if you suspect any unauthorized use of your
        account or access to your password. You are solely responsible for any and all
        use of your account. Passwords are subject to cancellation or suspension by
        ${COMPANY_NAME} at any time.
      `,
  },
  {
    title: `Using ${COMPANY_NAME}`,
    text: `Third-Party Content on ${COMPANY_NAME}. Content from other users, advertisers, and other third parties is made available to you through ${COMPANY_NAME}. ("Content") means any work of authorship or information, including salaries, company reviews, interview reviews, company photos, employer responses, job ads, employer profile information, advertisements, comments, opinions, postings, resumes, messages, text, files, images, photos, works of authorship, e-mail, data or other materials you find on ${COMPANY_NAME}. Because we do not control such Content, you understand and agree that: (1) we are not responsible for, and do not endorse, any such Content, including advertising and information about third-party products and services, job ads, or the employer, interview and salary-related information provided anonymously by other users; (2) we make no guarantees about the accuracy, currency, suitability, reliability or quality of the information in such Content; and (3) we assume no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful Content made available by users, advertisers, and third parties. In accordance with Section 230 of the U.S. Communications Decency Act, and any equivalent or similar laws in other jurisdictions which are intended to exclude or limit the liability of online service providers who provide access to user-generated content, we generally cannot be held liable for claims arising from the Content provided by third parties on ${COMPANY_NAME}.`,
  },
  {
    title: `Rules`,
    text: `You represent and warrant that you will use ${COMPANY_NAME} solely for lawful purposes in a manner consistent with these Terms and any and all applicable laws, regulations, or other legally enforceable obligations (including contractual obligations) you may have towards us and any third parties. You are solely responsible for any and all Content that is posted through your account on ${COMPANY_NAME} ("Your Content"). You agree that by submitting Your Content to ${COMPANY_NAME}, you have reviewed and understood our Community Guidelines. You understand that you may expose yourself to liability if Your Content or other use of ${COMPANY_NAME} violates applicable law or any third-party right. You agree that you will not:
    • Impersonate another person, or his or her email address, or misrepresent your current or former affiliation with an employer;
    • Create user accounts under false or fraudulent pretenses; create or use an account for anyone other than yourself; or create multiple active user accounts to post multiple reviews for the same company
    • Post Content that you do not own or have the right to post in accordance with the license set forth in these Terms;
    • Violate these Terms, the terms of your agreements with us, explicit restrictions set forth in our Community Guidelines, or any applicable law, rule or regulation;
    • Post Content that is defamatory, libelous, or fraudulent; that you know to be false or misleading; or that does not reflect your honest opinion and experience;
    • Act in a manner that is threatening, racist or bigoted, or is otherwise objectionable (as determined by ${COMPANY_NAME});
    • Promote, endorse or further illegal activities;
    • Disclose information in violation of any legally enforceable confidentiality, non-disclosure or other contractual restrictions or rights of any third party, including any current or former employers or potential employers;
    • Violate the privacy, publicity, copyright, patent, trademark, trade secret, or other intellectual property or proprietary rights of any third-party;
    • Post anything pornographic or sexually explicit in nature, or engage in the exploitation of persons in a sexual or violent manner;
    • Solicit personally identifying information from minors;
    • Except as expressly approved by us, use ${COMPANY_NAME} for commercial activities and/or promotions such as contests, sweepstakes, barter, pyramid schemes, advertising, affiliate links, and other forms of solicitation;
    • Imply a ${COMPANY_NAME} endorsement or partnership of any kind without our express written permission;
    • Send messages in violation of the USA CAN-SPAM Act or any other applicable anti-spam law;
    • Introduce software or automated agents to ${COMPANY_NAME}, or access ${COMPANY_NAME} so as to produce multiple accounts, generate automated messages, or to scrape, strip or mine data from ${COMPANY_NAME} without our express written permission;
    • Copy, modify or create derivative works of ${COMPANY_NAME} (including ${COMPANY_NAME} Content) without our express written permission;
    • Copy or use the information, content, or data on ${COMPANY_NAME} in connection with a competitive service (as determined by ${COMPANY_NAME});
    • Sell, resell, rent, lease, loan, trade or otherwise monetize access to ${COMPANY_NAME} or any ${COMPANY_NAME} Content without our express written permission;
    • Interfere with, disrupt, modify, reverse engineer, or decompile any data or functionality of ${COMPANY_NAME};
    • Interfere with, disrupt, or create an undue burden on ${COMPANY_NAME} or the networks or services connected to ${COMPANY_NAME};
    • Introduce any viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or similar software to ${COMPANY_NAME};
    • Attempt to circumvent any security feature of ${COMPANY_NAME}; or
    • Expose us or our users to any harm or liability.`,
  },
  {
    title: 'Links to Third-Party Websites',
    text: `${COMPANY_NAME} may contain links to third-party websites placed by us as a service to those interested in this information, or posted by other users. Your use of all such links to third-party websites is at your own risk. We do not monitor or have any control over, and make no claim or representation regarding third-party websites. To the extent such links are provided by us, they are provided only as a convenience, and a link to a third-party website does not imply our endorsement, adoption or sponsorship of, or affiliation with, such third-party website. When you leave ${COMPANY_NAME}, our terms and policies do not govern your use of third-party websites.`,
  },
  {
    title: 'Removal of Content',
    text: `While ${COMPANY_NAME} has no obligation to do so, ${COMPANY_NAME} reserves the right to review and delete (or modify) any Content that we believe, in our sole discretion, violates these Terms or other applicable policies posted on ${COMPANY_NAME} (including our Community Guidelines), or that we deem, in our sole discretion, inappropriate. If you see any Content on ${COMPANY_NAME} that you believe violates our policies, you may report that Content by clicking on an applicable link adjacent to that Content (e.g. links titled: "Inappropriate" or "Flag Review") or by contacting us here. Once notified, we will review the Content and consider whether to remove or modify it. Please note: Our interpretation of our policies and the decision whether or not to edit or remove Content is within our sole discretion. You understand and agree that if we choose not to remove or edit Content that you find objectionable, that decision will not constitute a violation of these Terms or any agreement we have with you.`,
  },
  {
    title: 'Usage of Feedback',
    text: `Feedback or any form of information regarding an entity's performance on a task is meant to be used in a constructive manner. The receiving party of any feedback will not and shall not dispute the validity of the feedback or any decision made due to any feedback. The receiving party of any feedback shall not use such feedback as a basis to seek legal action against ${COMPANY_NAME} or the giving party of such feedback. ${COMPANY_NAME} may use or publish any anonymous portions of any feedback or any anonymized feedback data.`,
  },
  {
    title: 'Other Enforcement Actions',
    text: `While we have no obligation to do so, we reserve the right to investigate and take appropriate action in our sole discretion against you if you violate these Terms, including without limitation: removing Content from ${COMPANY_NAME} (or modifying it); suspending your rights to use ${COMPANY_NAME}; terminating your membership and account; reporting you to law enforcement, regulatory authorities, or administrative bodies; and taking legal action against you.`,
  },
  {
    title: 'Rights to Your Content',
    text: `We do not claim ownership in any Content that you submit to ${COMPANY_NAME}, but to be able to legally provide ${COMPANY_NAME} to our users, we have to have certain rights to use such Content in connection with ${COMPANY_NAME}, as set forth below. By submitting any Content to ${COMPANY_NAME}, you hereby grant to us an unrestricted, irrevocable, perpetual, non-exclusive, fully-paid and royalty-free, license (with the right to sublicense through unlimited levels of sublicenses) to use, copy, perform, display, create derivative works of, adapt and distribute such Content in any and all media (now known or later developed) throughout the world. To the greatest extent permitted by applicable law, you hereby expressly waive any and all of your moral rights applicable to ${COMPANY_NAME}'s exercise of the foregoing license. No compensation will be paid with respect to the Content that you post through ${COMPANY_NAME}. You should only submit Content to ${COMPANY_NAME} that you are comfortable sharing with others under the terms and conditions of these Terms.`,
  },
  {
    title: 'Termination',
    text: `These Terms remain in effect while you use ${COMPANY_NAME} and, for registered users, as long as your account remains open. You may delete your account at any time. We may suspend or terminate your account or your access to parts of ${COMPANY_NAME}, without notice to you, if we believe that you have violated these Terms. All provisions of these Terms shall survive termination or expiration of these Terms except those granting access to or use of ${COMPANY_NAME}. We will have no liability whatsoever to you for any termination of your account or related deletion of your information.`,
  },
  {
    title: 'Changes to Terms',
    text: `We may revise these Terms from time by posting an updated version on ${COMPANY_NAME} and you agree that the revised Terms will be effective thirty (30) days after the change is posted. Your continued use of ${COMPANY_NAME} is subject to the most current effective version of these Terms.`,
  },
  {
    title: 'Limitation of liability',
    text: `In no event shall ${COMPANY_NAME}, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. ${COMPANY_NAME}, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.`,
  },
  {
    title: 'Indemnification',
    text: `You hereby indemnify to the fullest extent ${COMPANY_NAME} from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.`,
  },
];
