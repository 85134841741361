import * as React from 'react';
import { Card, Col, Row } from 'antd';

import styles from './TermsOfServicePage.module.scss';
import { terms } from './TermsOfServicePage.constant';

export const TermsOfService: React.FC = () => {
  return (
    <Row className={styles.TermsOfServicePage}>
      <Col xs={24} md={{ span: 16, offset: 4 }}>
        <Card className={styles.TermsOfServicePage__card}>
          <div className={styles.TermsOfServicePage__heading}>
            Terms of service
          </div>
          <div>
            {terms.map((obj, i) => (
              <section key={i} className={styles.TermsOfServicePage__section}>
                {obj.title && <div className={styles.TermsOfServicePage__title}>{obj.title}</div>}
                <div>{obj.text}</div>
              </section>
            ))}
          </div>
        </Card>
      </Col>
    </Row>
  );
}