import { combineReducers } from 'redux';

import { routerReducer } from 'src/redux/app.context';
import createAuthReducer from 'src/redux/auth/auth.reducer';

const createAppReducer = combineReducers({
  router: routerReducer,
  auth: createAuthReducer(),
});

export default createAppReducer;
