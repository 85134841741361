import { AxiosRequestConfig } from 'axios';
import { api } from '.';
import { IListDeal } from './deals';

const prefix = '/search';

export async function getSearch(params: { [key: string]: any } = {}, options: AxiosRequestConfig = {}) {
  const res = await api.v1.get<IListDeal[]>(prefix, {
    ...options,
    params,
  });
  return res.data;
}
