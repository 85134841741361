import * as React from 'react';
import { Card } from 'antd';
import {
  FireTwoTone,
} from '@ant-design/icons';
import DOMPurify from 'dompurify';
import { format } from 'timeago.js';
import { InlineProfileAvatar } from 'src/components/InlineProfileAvatar';
import { isDealHot } from 'src/utils/deals';

import { IDealDetailsProps } from './DealDetails.types';
import styles from './DealDetails.module.scss';
import editorStyles from 'src/components/RichEditor/RichEditor.module.scss';

export const DealDetails: React.FC<IDealDetailsProps> = (props) => {
  // TODO update posted at date text

  const cleanedDescription = DOMPurify.sanitize(props.description, {
    ALLOWED_ATTR: [
      'href',
      'target',
    ],
  });
  return (
    <Card
      className={styles.DealPage__details}
      title={
        <div>
          <div className={styles.DealDetails__title}>
            {isDealHot(props) && 
              <span className={styles.DealDetails__hotRating}>
                <FireTwoTone twoToneColor="#d32323" />
              </span>
            }
            {props.title}
          </div>
          <div className={styles.DealDetails__poster}>
            posted {format(props.createdAt)} by
            <span className={styles.DealDetails__avatar}>
              <InlineProfileAvatar
                profileImageUrl={props.poster.profileImageUrl}
                size="small"
              />
            </span>
            {props.poster.username}
          </div>
        </div>
      }
    >
      <div className={editorStyles.RichEditor__content} dangerouslySetInnerHTML={{__html: cleanedDescription }} />
    </Card>
  );
}
