import { ICloudinaryCreds, upload } from 'src/api/cloudinary';

export function makeFormData(creds: ICloudinaryCreds, file: File) {
  const formData = new FormData();
  formData.append('api_key', creds.apiKey);
  formData.append('timestamp', creds.timestamp.toString());
  formData.append('signature', creds.signature);
  formData.append('upload_preset', creds.uploadPreset);
  formData.append('file', file);

  return formData;
}

export async function uploadSingleImage(creds: ICloudinaryCreds, image: File) {
  const formData = makeFormData(creds, image);
  return upload(creds.cloudName, formData);
}

// TODO see if cloudinary supports an endpoint that batch uploads images
// https://cloudinary.com/documentation/upload_images
export async function uploadImages(creds: ICloudinaryCreds, images: File[]) {
  const uploads = [];
  for (let i = 0, len = images.length; i < len; i++) {
    const image = images[i];
    uploads.push(uploadSingleImage(creds, image));
  }

  // return array of res data objects
  return Promise.all(uploads).then((res) => res.map((item) => item.data));
}
