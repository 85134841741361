// File for dev only

import { IListDeal } from './deals';

export const deals: IListDeal[] = [
  {
    id: '1',
    title: 'Apple iPhone 12 mini Leather Case w/ MagSafe (Black)',
    imageUrls: [
      'https://slickdeals.net/attachment/6/3/5/4/7/1/11162641.attach',
      'https://slickdeals.net/attachment/6/3/5/4/7/1/11162644.attach',
    ],
    dealPrice: 10.00,
    retailPrice: 59.00,
    currency: 'USD',
    poster: {
      username: 'daisybeetle',
      profileImageUrl: 'https://static.slickdealscdn.com/attachment/avatar/6/3/5/4/7/1/150x150/avatar.normal?dateline=1408591769',
    },
    dealUrl: 'https://www.att.com/buy/accessories/cases/apple-iphone-leather-case-plus-magsafe-iphone-12-mini-black.html?cjevent=733f7c1b8e2711ec83770cfb0a82b820&source=EC1NAT10600aff12A&wtExtndSource=4485850',
    // TODO description should be stylized paragraph
    description: `
    AT&T has Apple iPhone 12 mini Leather Case w/ MagSafe (Black) on sale for $10. Shipping is free.

    Alternatively, AT&T has Pelican Kevlar Shield iPhone 12 mini Case w/ Holster (Black) on sale for $10. Shipping is free.
    
    Thanks Slickdeals Deal Hunter daisybeetle for posting this deal.
    
    About the Apple iPhone 12 mini Leather Case:
    Made from specially tanned and finished leather
    Magnetic attach and charging
    Compatible with Qi wireless charging
    Designed by Apple
    `,
    retailer: 'Amazon',
    rating: 40,
    commentsCount: 4,
    hotRating: 5,
  },
  {
    id: '2',
    title: 'Anker PowerPort III Nano 20W PIQ USB 3.0 USB-C Fast Charger w/ Foldable Plug',
    imageUrls: [
      'https://slickdeals.net/attachment/3/6/9/5/2/8/11173420.attach',
      'https://slickdeals.net/attachment/8/6/6/3/0/11172580.attach',
      'https://slickdeals.net/attachment/8/6/6/3/0/11172583.attach',
      'https://slickdeals.net/attachment/8/6/6/3/0/11172586.attach',
    ],
    dealPrice: 9.60,
    retailPrice: 15.99,
    currency: 'USD',
    poster: {
      username: 'Discombobulated',
      profileImageUrl: 'https://static.slickdealscdn.com/attachment/avatar/8/6/6/3/0/150x150/avatar.normal?dateline=1432166274',
    },
    dealUrl: 'https://www.amazon.com/gp/product/B08T67HDH1/ref=ox_sc_act_title_2?tag=slickdeals09-20&ascsubtag=8316a7368e2a11ecb001aea895ef3e670INT&smid=A1KWJVS57NX03I',
    description: `
    Woot via Amazon has select Anker 20W USC 3.0 Chargers on sale for the prices listed. Shipping is free w/ Prime or on orders of $25+.

    Thanks to Deal Editor Discombobulated for finding this deal.
    
    Available:
    Anker PowerPort III Nano 20W PIQ USB 3.0 USB-C Fast Charger w/ Foldable Plug (White) $9.59
    Anker Nano Pro 20W PIQ 3.0 USB 3.0 USB-C Compact Charger (Cool Lavender) $14.99
    Product Features (Fast Charger):
    Compatible Devices: IPad 10.2-inch 2021; iPad mini 8.3-inch 2021; iPad Pro 12.9-inch 2020 / 2018, iPad Pro 11-inch 2020 / 2018 iPad Air 2020; iPad 2020; iPad mini 5th generation; AirPods, Apple Watch (requires an Apple Watch magnetic charging cable with USB-C connector)
    Power to charge the iPhone 13 series from 0% to 50% in 25 minutes
    Provides up to 20W charging to most flagship phones including iPhone and Samsung Galaxy S20 as well as smartwatches and earbuds
    `,
    retailer: 'Amazon',
    rating: 12,
    commentsCount: 8,
    hotRating: 4,
  },
  {
    id: '3',
    title: 'Apple AirPods Pro w/ MagSafe Wireless Charging Case',
    imageUrls: [
      'https://slickdeals.net/attachment/7/8/7/6/5/0/3/11164534.attach',
      'https://slickdeals.net/attachment/7/8/7/6/5/0/3/11164537.attach',
    ],
    dealPrice: 175,
    retailPrice: 249,
    currency: 'USD',
    poster: {
      username: 'kkdealz',
      profileImageUrl: 'https://static.slickdealscdn.com/images/avatar/sd/redesign/Star.png',
    },
    dealUrl: 'https://www.amazon.com/gp/product/B09JQMJHXY/ref=ox_sc_act_title_1?tag=slickdeals09-20&ascsubtag=f311135a8e2a11ecb810ee7b3f3e8d1f0INT&smid=ATVPDKIKX0DER&psc=1',
    description: `
    Amazon has Apple AirPods Pro w/ MagSafe Wireless Charging Case (MLWK3AM/A) on sale for $174.98. Shipping is free.

    Walmart also has Apple AirPods Pro w/ MagSafe Wireless Charging Case (MLWK3AM/A) on sale for $174.98. Shipping is free, otherwise free store pickup is available where stock permits.
    
    Thanks to Community Members kkdealz & matchnbpm for finding this deal.
    
    Key Features
    Active Noise Cancellation blocks outside noise, so you can immerse yourself in music
    Transparency mode for hearing and interacting with the world around you
    Spatial audio with dynamic head tracking places sound all around you
    Adaptive EQ automatically tunes music to your ears
    Three sizes of soft, tapered silicone tips for a customizable fit
    Force sensor lets you easily control your entertainment, answer or end calls, and more
    Sweat and water resistant
    More than 24 hours total listening time with the MagSafe Charging Case
    Quick access to Siri by saying "Hey Siri"
    Effortless setup, in-ear detection, and automatic switching for a magical experience
    Easily share audio between two sets of AirPods on your iPhone, iPad, iPod touch, or Apple TV
    `,
    retailer: 'Amazon',
    rating: 32,
    commentsCount: 18,
    hotRating: 5,
  },
  {
    id: '4',
    title: 'AT&T 10W Wireless Charging Pad w/ 5 Charging Cable',
    imageUrls: [
      'https://static.slickdealscdn.com/attachment/5/1/4/8/2/1/3/11165299.attach',
    ],
    dealPrice: 5,
    retailPrice: 10.00,
    currency: 'USD',
    poster: {
      username: 'daisybeetle',
      profileImageUrl: 'https://static.slickdealscdn.com/attachment/avatar/6/3/5/4/7/1/150x150/avatar.normal?dateline=1408591769',
    },
    dealUrl: 'https://www.att.com/buy/accessories/chargers/att-10w-wireless-charging-pad-plus-charging-cable-black.html?cjevent=72f1fbfb8e2b11ec81fd004a0a82b82d&source=EC1NAT10600aff12A&wtExtndSource=4485850',
    description: `
    Update: This very popular deal is still available.

    AT&T has AT&T 10W Wireless Charging Pad w/ 5' Charging Cable on sale for $5. Shipping is free.
    
    Thanks to Deal Hunter daisybeetle for sharing this deal.
    Note: AC Adapter sold separately.
    Key Features:
    Universal Qi compatibility
    Industry-leading safety features, preventing overheating
    Soft grip surface for enhanced stability
    Includes 5ft USB-A to USB-C Cable
    Input: 5VDC/2A; 9VDC/1.67A
    Output: Up to 10W
    One-year limited warranty
    `,
    retailer: 'AT&T',
    rating: 6,
    commentsCount: 2,
    hotRating: 3,
  },
  {
    id: '5',
    title: '32" Dell S3222DGM 1440p 165Hz 1ms VA Curved FreeSync Gaming Monitor',
    imageUrls: [
      'https://slickdeals.net/attachment/9/5/3/2/3/11163577.attach',
      'https://slickdeals.net/attachment/9/5/3/2/3/11163580.attach',
      'https://slickdeals.net/attachment/9/5/3/2/3/11163583.attach',
    ],
    dealPrice: 300,
    retailPrice: 529.99,
    currency: 'USD',
    poster: {
      username: 'iconian',
      profileImageUrl: 'https://static.slickdealscdn.com/attachment/avatar/9/5/3/2/3/150x150/avatar.normal?dateline=1583302510',
    },
    dealUrl: 'https://deals.dell.com/en-us/productdetail/czrr?AID=4485850&cjevent=fa2f172f8e2b11ec830d7a5d0a82b824&publisher=&cjdata=MXxOfDB8WXww&gacd=9614781-23761182-5750457-265988609-127889515&dgc=af&VEN1=12578053-4485850-fa0285768e2b11ecb8c9aeb9af6f605e0INT-Slickdeals%20LLC&dclid=CKCIw-yPgfYCFSFBDQodf9cAxQ&nclid=ZVpqtsR26ZGSpwL0jjDSVYpHvVXn_JGU-wYnZe3oZV07K8XyqwU3bCbDOMzKH2ta',
    description: `
    Dell Member Purchase Program has 32" Dell S3222DGM 1440p 165Hz 1ms VA Curved FreeSync Premium Gaming Monitor for $299.99. To earn Slickdeals Cashback, before purchase, follow the cashback instructions below (PC extension required, before checkout). Shipping is free.

    Thanks to Deal Editor iconian for finding this deal.
    
    Specs:
    Resolution: 2560x1440 (2K QHD)
    Response Time: 1ms (MPRT) / 2ms (gray to gray) in Extreme Mode
    Refresh Rate: 165Hz (w/ DisplayPort 1.2, 144Hz w/ HDMI 2.0)
    Monitor Type: VA
    Viewing Angles: 178/178 (Horizontal/Vertical)
    Brightness: 350 cd/m²
    Curvature: 1800r
    AMD FreeSync Premium
    VESA Mount: 100x100mm
    Inputs
    2x HDMI (HDCP 2.2)
    1x DisplayPort 1.2
    `,
    retailer: 'Dell',
    rating: 12,
    commentsCount: 7,
    hotRating: 5,
  },
  {
    id: '6',
    title: 'Comfort Zone 1200W Ceramic Portable Electric Fireplace Heater',
    imageUrls: [
      'https://slickdeals.net/attachment/1/3/5/8/6/2/3/11168227.attach',
      'https://slickdeals.net/attachment/1/3/5/8/6/2/3/11168572.attach',
      'https://slickdeals.net/attachment/1/3/5/8/6/2/3/11168575.attach',
      'https://slickdeals.net/attachment/1/3/5/8/6/2/3/11168578.attach',
      'https://slickdeals.net/attachment/1/3/5/8/6/2/3/11168581.attach',
    ],
    dealPrice: 24,
    retailPrice: 47.98,
    currency: 'USD',
    poster: {
      username: 'Rokket',
      profileImageUrl: null,
    },
    dealUrl: 'https://www.walmart.com/ip/Comfort-Zone-1200W-Ceramic-Portable-Electric-Fireplace-Heater-Matte-Red/999661991?irgwc=1&sourceid=imp_QU3wJbU8IxyITwd1CVwdlW4xUkGR1AzBW3cKzI0&veh=aff&wmlspartner=imp_2189989&clickid=QU3wJbU8IxyITwd1CVwdlW4xUkGR1AzBW3cKzI0&sharedid=&affiliates_ad_id=612734&campaign_id=9383',
    description: `
    `,
    retailer: 'Walmart',
    rating: 240,
    commentsCount: 11,
    hotRating: 2,
  },
];

export const accountUser = {
  username: 'doubledown',
  profileImageUrl: 'https://joeschmoe.io/api/v1/rando',
  firstName: '',
  lastName: '',
  email: 'joe@example.com',
}