import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'antd';
import { DAYS_BEFORE_QP_KEY } from 'src/constants/search';

import { IDaysBeforeSelectProps, EDaysBeforeOptionText, EDaysBeforeOptionValue } from './DaysBeforeSelect.types';

const { Option } = Select;

// Think about adding last activity [comments, post updates]
export const DaysBeforeSelect: React.FC<IDaysBeforeSelectProps> = (props) => {
  const [searchParams] = useSearchParams();
  const sort = searchParams.get(DAYS_BEFORE_QP_KEY);

  return (
    <Select
      defaultValue={EDaysBeforeOptionValue.anyTime}
      onChange={props.onChange}
      value={(sort && parseInt(sort)) || EDaysBeforeOptionValue.anyTime}
    >
      {Object.entries(EDaysBeforeOptionText)
        .map(([key, val]: [string, string], i: number) => (
          <Option
            key={i}
            value={EDaysBeforeOptionValue[key as keyof typeof EDaysBeforeOptionValue]}
          >{val}</Option>
      ))}
    </Select>
  );
}