import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history, store } from 'src/redux/app.store';

import 'src/antd.less';
import 'src/index.scss';
import 'src/styles/_typography.scss';

import * as serviceWorker from './serviceWorker';

import App from './app';
import { GlobalModalProvider } from './components/GlobalModal';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <GlobalModalProvider>
            <App />
          </GlobalModalProvider>
        </QueryClientProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
