import * as React from 'react';
import { Card } from 'antd';

import { NoResultsSubscribeForm } from '../NoResultsSubscribeForm';
import { postSubscribeNoSearchResults } from 'src/api/subscribe';
import { INoResultsProps } from './NoResults.types';

import styles from './NoResults.module.scss';

export const NoResults: React.FC<INoResultsProps> = (props) => {
  const [successfulSub, setSuccessfulSub] = React.useState<boolean>(false);

  function action({ email }: {
    email: string;
  }): Promise<any> {
    return postSubscribeNoSearchResults({
      email,
      data: Object.fromEntries(
        Object.entries(props.searchParams)
          .filter(([, val]) => !!val)),
    });
  }

  function onSuccess() {
    setSuccessfulSub(true);
  }

  return (
    <div className={styles.NoResults}>
      <Card>
        {successfulSub ?
          <div className={styles.NoResults__message}>
            We'll let you know when we get some deals you'll be interested in!
          </div>
          :
          <>
            <div className={styles.NoResults__message}>
              There are no deals for <span className={styles.NoResults__query}>{props.searchParams.q}</span> right now.
            </div>
            <div className={styles.NoResults__message}>
              Enter your email below and we'll find some deals for you!
            </div>
            <NoResultsSubscribeForm
              action={action}
              layout="inline"
              onSuccess={onSuccess}
            />
          </>
        }
      </Card>
    </div>
  );
}