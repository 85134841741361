import { useSearchParams } from 'react-router-dom';

// keep in sync with backend src/search/search.constants.ts
export const SEARCH_QP_KEY = 'q';
export const DAYS_BEFORE_QP_KEY = 'daysBefore';
export const MIN_PRICE_QP_KEY = 'minPrice';
export const MAX_PRICE_QP_KEY = 'maxPrice';
export const SORT_QP_KEY = 'sort';

export const searchQueryKeys: string[] = [
  SEARCH_QP_KEY,
  DAYS_BEFORE_QP_KEY,
  MIN_PRICE_QP_KEY,
  MAX_PRICE_QP_KEY,
  SORT_QP_KEY,
];

export function useDealSearchParams() {
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  return searchQueryKeys.reduce((obj: { [key: string]: string }, key: string) => {
    obj[key] = queryParams[key];
    return obj;
  }, {});
}
