import { Action, Reducer } from 'redux';
import actionCreatorFactory, { isType } from 'typescript-fsa';
import produce from 'immer';
import Cookies from 'js-cookie';

import { AuthStore, initialAuthStore } from 'src/redux/auth/auth.store';
import { ERequestStatus } from 'src/api';

const actionCreators = actionCreatorFactory('auth');

export const actions = {
  fetchUser: actionCreators.async<void, any>('FETCH_USER'),
  initUserApp: actionCreators.async<void, void>('INIT_USER_APP'),
  login: actionCreators.async<{
    username: string;
    password: string;
  }, void>('LOGIN'),
  logout: actionCreators.async<void, void>('LOGOUT'),
};

export const createAuthReducer = (): Reducer<AuthStore> => {
  return (state: AuthStore = initialAuthStore, action: Action) => {
    if (isType(action, actions.fetchUser.done)) {
      const user = action.payload.result;
      return produce(state, (draftState) => {
        draftState.currentUser = user;
      });
    }
    if (isType(action, actions.login.started)) {
      return produce(state, (draftState) => {
        draftState.loginStatus = ERequestStatus.PENDING;
      });
    }
    if (isType(action, actions.login.done)) {
      return produce(state, (draftState) => {
        draftState.loginStatus = ERequestStatus.DONE;
        // probably can be just set to true, but just in case
        draftState.isLoggedIn = !!Cookies.get('loggedIn');
      });
    }
    if (isType(action, actions.login.failed)) {
      return produce(state, (draftState) => {
        draftState.loginStatus = ERequestStatus.FAILED;
      });
    }
    if (isType(action, actions.logout.done)) {
      return produce(state, (draftState) => {
        draftState.currentUser = undefined;
        draftState.initAppStatus = ERequestStatus.DEFAULT;
        draftState.loginStatus = ERequestStatus.DEFAULT;
        // probably can be just set to false, but just in case
        draftState.isLoggedIn = !!Cookies.get('loggedIn');
      });
    }
    if (isType(action, actions.initUserApp.started)) {
      return produce(state, (draftState) => {
        draftState.initAppStatus = ERequestStatus.PENDING;
      });
    }
    if (isType(action, actions.initUserApp.done)) {
      return produce(state, (draftState) => {
        draftState.initAppStatus = ERequestStatus.DONE;
      });
    }
    if (isType(action, actions.initUserApp.failed)) {
      return produce(state, (draftState) => {
        draftState.initAppStatus = ERequestStatus.FAILED;
      });
    }
    return state;
  };
}

export default createAuthReducer;
