import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'antd';

import { SearchBarForm } from './SearchBarForm';

import { IHeaderProps } from './Header.types';

export const LoggedInHeader: React.FC<IHeaderProps> = function(props) {
  return (
    <div>
      <Row>
        <Col xs={4}>
          <Link to="/">Home</Link>
        </Col>
        <Col xs={16}>
          <SearchBarForm />
        </Col>
        <Col xs={4}>
          <Link to="/post-deal">
            <Button>Share a deal</Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
